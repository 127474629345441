<template>
  <v-col class="px-0 py-0">
    <success-snackbar v-if="showSuccessSnackbar" :snackbarText="snackarText" />
    <v-row no-gutters align="center" style="margin-top: 40px">
      <div
        class="statusBox"
        @click="activeStatus = 'All'"
        :class="activeStatus == 'All' ? 'activeStatusBox' : ''"
      >
        Усі
      </div>
      <div
        class="statusBox"
        @click="activeStatus = 'Active'"
        :class="activeStatus == 'Active' ? 'activeStatusBox' : ''"
      >
        Активні
      </div>
      <div
        class="statusBox"
        @click="activeStatus = 'Paused'"
        :class="activeStatus == 'Paused' ? 'activeStatusBox' : ''"
      >
        Призупинені
      </div>
    </v-row>
    <change-lang-form @setLang="setLang" />
    <div class="sectionBackground">
      <p class="sectionTitle">Налаштування пільгової системи</p>
      <p class="sectionText">
        Додайте пільгові знижки для різних верств населення
      </p>
      <Loader v-if="showLoader" />
      <v-col class="px-0 py-0" v-else>
        <v-row
          no-gutters
          align="center"
          v-for="social in socialList"
          :key="social.id"
          style="margin-bottom: 20px"
        >
          <v-col class="py-0" cols="6" style="padding-right: 20px">
            <v-text-field
              outlined
              dense
              label="Пільгова категорія"
              placeholder="Введіть тут.."
              hide-details
              style="border-radius: 10px"
              height="48px"
              color="#E2E2E2"
              background-color="#FFF"
              v-model="social.name[activeLang]"
              @input="social.is_edited = true"
            />
          </v-col>
          <v-col class="py-0" cols="4" style="padding-right: 20px">
            <v-text-field
              outlined
              dense
              label="Відсоток знижки"
              placeholder="Введіть тут.."
              hide-details
              style="border-radius: 10px"
              height="48px"
              color="#E2E2E2"
              background-color="#FFF"
              v-model="social.amount"
              @input="social.is_edited = true"
            />
          </v-col>
          <v-col class="py-0 px-0" cols="2">
            <v-menu offset-y rounded="lg">
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="statusBox statusName"
                  v-bind="attrs"
                  v-on="on"
                  style="
                    width: 100%;
                    height: 48px;
                    display: grid;
                    place-items: center;
                  "
                  :style="
                    social.status == 'Active'
                      ? 'color: #149e51; background: rgba(20, 158, 81, 0.10); '
                      : 'color: #E19700; background: rgba(254, 213, 0, 0.10);'
                  "
                >
                  {{ social.status == "Active" ? "Активний" : "Призупинено" }}
                </div>
              </template>
              <v-col style="padding: 10px; background-color: #fff">
                <div
                  style="
                    border-radius: 9px;
                    background: #149e511a;
                    color: #149e51;
                    padding: 8px 16px;
                    display: grid;
                    place-items: center;
                    width: 100%;
                    cursor: pointer;
                  "
                  class="statusName"
                  v-if="social.status !== 'Active'"
                  @click="(social.status = 'Active'), (social.is_edited = true)"
                >
                  Активний
                </div>
                <div
                  v-if="social.status !== 'Paused'"
                  style="
                    border-radius: 9px;
                    background: rgba(254, 213, 0, 0.1);
                    color: #e19700;
                    padding: 8px 16px;
                    display: grid;
                    place-items: center;
                    width: 100%;
                    cursor: pointer;
                  "
                  class="statusName"
                  @click="(social.status = 'Paused'), (social.is_edited = true)"
                >
                  Призупинено
                </div>
              </v-col>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <add-new-item-btn
        :disabled="$v.$invalid"
        text="Додати пільгу"
        @click="addNewItem"
      />
    </div>
    <pagination
      :page="page"
      :quantityPage="quantityPage"
      @changePage="changePage"
    />
    <submit-button
      style="
        margin-top: 40px;
        height: 48px !important;
        font-size: 20px;
        font-weight: 700;
      "
      :disabled="$v.$invalid || isDisable"
      @click="saveChanges"
      >Зберегти зміни</submit-button
    >
    <v-row no-gutters justify="center">
      <cancel-btn
        style="margin-top: 10px"
        text="Скасувати"
        @click="cancelChanges"
      />
    </v-row>
  </v-col>
</template>

<script>
import { validationMixin } from "vuelidate";
import addNewItemBtn from "../../../UI/Buttons/addNewItemBtn.vue";
import { required } from "vuelidate/lib/validators";
import SubmitButton from "../../../UI/Buttons/submitButton.vue";
import CancelBtn from "../../../UI/Buttons/cancelBtn.vue";
import requestFormData from "../../../../requests/requestFormData";
import socialService from "../../../../requests/Admin/Discount/socialService";
import Loader from "../../../UI/Loader.vue";
import SuccessSnackbar from "../../../UI/Snackbars/successSnackbar.vue";
import ChangeLangForm from "../../../UI/Forms/changeLangForm.vue";
import Pagination from "../../../UI/pagination.vue";
export default {
  mixins: [validationMixin],
  components: {
    addNewItemBtn,
    SubmitButton,
    CancelBtn,
    Loader,
    Pagination,
    SuccessSnackbar,
    ChangeLangForm,
  },
  data: () => ({
    activeStatus: "All",
    activeLang: "ua",
    socialList: [],
    showLoader: true,
    snackarText: "",
    showSuccessSnackbar: false,
    page: 1,
    quantityPage: 0,
  }),
  validations: {
    socialList: {
      $each: {
        name: {
          required,
        },
        amount: {
          required,
        },
      },
    },
  },
  mounted() {
    this.getSocials();
  },
  methods: {
    addNewItem() {
      this.socialList.push({
        id: new Date().getTime(),
        name: {
          ua: "",
          en: "",
          pl: "",
        },
        amount: "",
        status: "Active",
        is_new: true,
      });
    },
    changePage(page) {
      this.page = page;
      this.showLoader = true;
      this.getSocials();
    },
    cancelChanges() {
      if (
        this.socialList.findIndex((social) => social.is_edited == true) !== -1
      ) {
        this.showLoader = true;
        this.getSocials();
      }
    },
    setLang(lang) {
      this.activeLang = lang;
    },
    saveChanges() {
      this.socialList.forEach((social) => {
        if (social.is_new) {
          this.createNewSocial(social);
        } else if (social.is_edited) {
          this.updateSocial(social);
        }
      });
      this.socialList.map((social) => (social.is_edited = false));
    },
    async getSocials() {
      await socialService
        .getSocials(this.activeStatus, this.page)
        .then((res) => {
          if (res.status == "Success") {
            this.socialList = res.data.map(
              (social) =>
                (social = {
                  ...social,
                  name: {
                    ua: social.translations.find(
                      (translate) => translate.lang == "ua"
                    )?.title,
                    en: social.translations.find(
                      (translate) => translate.lang == "en"
                    )?.title,
                    pl: social.translations.find(
                      (translate) => translate.lang == "pl"
                    )?.title,
                  },
                  is_edited: false,
                })
            );
            this.quantityPage = res.pagination.meta.last_page;
            this.showLoader = false;
          }
        });
    },
    async createNewSocial(item) {
      if (!this.$v.$invalid) {
        let tenYears = new Date();
        tenYears.setFullYear(tenYears.getFullYear() + 10);
        const data = {
          amount: item.amount * 100,
          amount_type: "Percent",
          discount_type: "Season",
          route_type: "All",
          status: item.status,
          started_at: new Date().toISOString().substring(0, 10),
          ended_at: new Date(tenYears).toISOString().substring(0, 10),
          translations: [
            {
              lang: "ua",
              title: item.name.ua,
              description: item.name.ua,
            },
            {
              lang: "en",
              title: item.name.en !== "" ? item.name.en : item.name.ua,
              description: item.name.en !== "" ? item.name.en : item.name.ua,
            },
            {
              lang: "pl",
              title: item.name.pl !== "" ? item.name.pl : item.name.ua,
              description: item.name.pl !== "" ? item.name.pl : item.name.ua,
            },
          ],
        };
        let form = requestFormData.jsonToFormData(data);
        await socialService.createSocial(form).then((res) => {
          if (res.status == "Success") {
            this.snackarText = "Пільгу успішно створено";
            this.showSuccessSnackbar = true;
            setTimeout(() => {
              this.showSuccessSnackbar = false;
            }, 3000);
            this.showLoader = true;
            this.getSocials();
          }
        });
      }
    },
    async updateSocial(item) {
      if (!this.$v.$invalid) {
        let tenYears = new Date();
        tenYears.setFullYear(tenYears.getFullYear() + 10);
        let ua_translate = item.translations.find(
          (translate) => translate.lang == "ua"
        );
        let en_translate = item.translations.find(
          (translate) => translate.lang == "en"
        );
        let pl_translate = item.translations.find(
          (translate) => translate.lang == "pl"
        );
        const data = {
          amount: item.amount * 100,
          amount_type: "Percent",
          discount_type: "Season",
          route_type: "All",
          status: item.status,
          started_at: item.started_at,
          ended_at: item.ended_at,
          translations: [
            {
              id: ua_translate.id,
              slug: ua_translate.slug,
              lang: "ua",
              title: item.name.ua,
              description: item.name.ua,
            },
            {
              id: en_translate.id,
              slug: en_translate.slug,
              lang: "en",
              title: item.name.en,
              description: item.name.en,
            },
            {
              id: pl_translate.id,
              slug: pl_translate.slug,
              lang: "pl",
              title: item.name.pl,
              description: item.name.pl,
            },
          ],
        };
        let form = requestFormData.jsonToFormData(data);
        await socialService.updateSocial(item.id, form).then((res) => {
          if (res.status == "Success") {
            console.log("WORK");
          }
        });
      }
    },
  },
  computed: {
    isDisable() {
      let is_new_index = this.socialList.findIndex(
        (social) => social.is_new == true
      );
      let is_edited_index = this.socialList.findIndex(
        (social) => social.is_edited == true
      );
      return is_new_index == -1 && is_edited_index == -1;
    },
  },
  watch: {
    activeStatus: {
      handler() {
        this.showLoader = true;
        this.getSocials();
      },
    },
  },
};
</script>

<style>
</style>